import { Col, Grid, Row } from "react-bootstrap"
import StrataDocumentsModal from "../pages/documents/strata-documents-modal"
import React, { useState } from "react"

const TitleHeader = (props) => {
  const [currentForm, setCurrentForm] = useState("");
  const strataProposalRequest = 'strataProposalRequest'
  const strataProposalRequestUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/10'
  const rentalManagementProposalRequest = 'rentalManagementProposalRequest'
  const rentalManagementProposalRequestUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/11'
  const developerServicesForm = 'developerServices'
  const developerServicesUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/17'
  return (
    <div className="background-image">
      <Grid>
        <Row>
          <Col md={12}>
            <h1 className="parallax-title">{props.title}</h1>
          </Col>
        </Row>
      </Grid>
      <Grid>
        <section className="background">
          <div className="row center">
            <Row className="pt-4">
              <Col md={12}>
                {props.title === "Strata Management Services" &&
                  <button
                    className="btn btn-outline btn-primary-1 text-uppercase parallax-button"
                    onClick={() => setCurrentForm(strataProposalRequest)}>
                    Strata Management Proposal Request
                    <StrataDocumentsModal
                      open={currentForm === strataProposalRequest}
                      openModal={setCurrentForm}
                      documentUrl={strataProposalRequestUrl}/>
                  </button>
                }
                {props.title === "Rental Management Services" &&
                  <button
                    className="btn btn-outline btn-primary-1 text-uppercase parallax-button"
                    onClick={() => setCurrentForm(rentalManagementProposalRequest)}>
                    Rental Management Proposal Request
                    <StrataDocumentsModal
                      open={currentForm === rentalManagementProposalRequest}
                      openModal={setCurrentForm}
                      documentUrl={rentalManagementProposalRequestUrl}/>
                  </button>
                }
                {props.title === "Developer Services" &&
                  <button
                    className="btn btn-outline btn-primary-1 text-uppercase parallax-button"
                    onClick={() => setCurrentForm(developerServicesForm)}>
                    Developer Services Proposal Request
                    <StrataDocumentsModal
                      open={currentForm === developerServicesForm}
                      openModal={setCurrentForm}
                      documentUrl={developerServicesUrl}/>
                  </button>
                }
              </Col>
            </Row>
          </div>
        </section>
      </Grid>
    </div>
  )
}

  export default TitleHeader;