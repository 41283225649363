import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Col } from "react-bootstrap"
import StrataDocumentsModal from "./strata-documents-modal"
import TitleHeader from "../../components/title-header"

const StrataManagementDocuments = () => {
  const [currentForm, setCurrentForm] = useState("");

  const bylawAndRulesViolationComplaint = 'bylawAndRulesViolationComplaint'
  const bylawAndRuleViolationDispute = 'bylawAndRuleViolationDispute'
  const changeOfAddress = 'changeOfAddress'
  const chargebackDispute = 'chargebackDispute'
  const CRT = 'CRT'
  const financialHardship = 'financialHardship'
  const informationSheet = 'informationSheet'
  const latePayment = 'latePayment'
  const PADAgreement = 'PADAgreement'
  const PADWithdrawal = 'PADWithdrawal'
  const renovationRequest = 'renovationRequest'
  const documentOrder = 'documentOrder'

  const bylawAndRulesViolationComplaintUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/1'
  const bylawAndRuleViolationDisputeUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/3'
  const changeOfAddressUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/13'
  const chargebackDisputeUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/5'
  const CRTUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/16'
  const documentOrderFormUrl = 'https://www.cognitoforms.com/KoreckiRealEstateServicesInc/StrataCorporationDocumentOrderFormFormBF'
  const financialHardshipUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/9'
  const informationSheetUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/14'
  const latePaymentUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/6'
  const PADAgreementUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/7'
  const PADWithdrawalUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/8'
  const renovationRequestUrl = 'https://www.cognitoforms.com/f/W1oWZRgNeEyMzPEiawDi3Q/15'

  return (
    <Layout>
      <SEO title="Strata Management | Korecki Real Estate Services" description="Korecki Real Estate Services is dedicated to providing strata corporations with the highest level of service and ensuring smooth building operations. With extensive experience in the Strata Management field, you can rely on Korecki Real Estate Services to provide sound advice and guidance." keywords="korecki,Real Estate,Strata Management services, Strata, Strata Management, Strata Property Management, Strata Management, Property Management, Client Property Account System"/>
      <div>
        <TitleHeader title={"Strata Management Documents"}/>
        {/*The following code is not from contentful*/}
        <Col md={12} className="blocktext-documents-page" style={{ backgroundColor: 'rgba(250,250,250)', paddingLeft: '200px'}}>
          <section className="section background">
            <Row className="custom-sm-flex-order">
              <p className="middle justified-black" style={{fontSize: '20px'}}>Please click on and complete the relevant request form</p>
            </Row>
          </section>
          <Row style={{width: '60%', marginLeft: 'auto', marginRight: 'auto'}}>
          <Col md={6} className="half-section">
          <section className="section background">
            <Row className="custom-sm-flex-order center">
              <div className="center-button">
              <h1 className="text-uppercase font-weight-bold small-h1" style={{marginBottom: '5px'}}>Complaints</h1>
              </div>
              <div className="center-button">
              <button onClick={() => setCurrentForm(bylawAndRulesViolationComplaint)} className="btn btn-outline btn-primary-1 text-uppercase form-link-buttons">
                <StrataDocumentsModal
                  open={currentForm===bylawAndRulesViolationComplaint}
                  openModal={setCurrentForm}
                  documentUrl={bylawAndRulesViolationComplaintUrl}/>
                Bylaw And Rules Violation Complaint
              </button>
              </div>
            </Row>
          </section>
          </Col>
          <Col md={6} className="half-section">
          <section className="section background">
            <div className="center-button">
              <h1 className="text-uppercase font-weight-bold small-h1 middle" style={{marginBottom: '5px'}}>Contact Updates</h1>
              <p className="text-md text-color-dark"></p>
            </div>
            <Row className="custom-sm-flex-order">
              <div className="center-button">
                <button onClick={() => setCurrentForm(changeOfAddress)} className="btn btn-outline btn-primary-1 text-uppercase form-link-buttons">
                  <StrataDocumentsModal
                    open={currentForm===changeOfAddress}
                    openModal={setCurrentForm}
                    documentUrl={changeOfAddressUrl}/>
                  Change of Address & Contact Details
                </button>
              </div>
            </Row>
              <div className="center-button">
                <button onClick={() => setCurrentForm(informationSheet)} className="btn btn-outline btn-primary-1 text-uppercase margin-top form-link-buttons">
                  <StrataDocumentsModal
                    open={currentForm===informationSheet}
                    openModal={setCurrentForm}
                    documentUrl={informationSheetUrl}/>
                  Information Sheet & Emergency Contact List
                </button>
              </div>
          </section>
          </Col>
          <Col md={6} className="half-section">
          <section className="section background">
            <div className="center-button">
              <h1 className="text-uppercase font-weight-bold small-h1 middle" style={{marginBottom: '5px'}}>Disputes</h1>
              <p className="text-md text-color-dark"></p>
            </div>
            <Row className="custom-sm-flex-order">
              <div className="center-button">
                <button onClick={() => setCurrentForm(bylawAndRuleViolationDispute)} className="btn btn-outline btn-primary-1 text-uppercase form-link-buttons">
                  <StrataDocumentsModal
                    open={currentForm===bylawAndRuleViolationDispute}
                    openModal={setCurrentForm}
                    documentUrl={bylawAndRuleViolationDisputeUrl}/>
                  Bylaw And Rule Violation Dispute
                </button>
              </div>
            </Row>
            <div className="center-button">
              <button onClick={() => setCurrentForm(chargebackDispute)} className="btn btn-outline btn-primary-1 text-uppercase margin-top form-link-buttons">
                <StrataDocumentsModal
                  open={currentForm===chargebackDispute}
                  openModal={setCurrentForm}
                  documentUrl={chargebackDisputeUrl}/>
                Chargeback Dispute
              </button>
            </div>
            <div className="center-button">
              <button onClick={() => setCurrentForm(CRT)} className="btn btn-outline btn-primary-1 text-uppercase margin-top form-link-buttons">
                <StrataDocumentsModal
                  open={currentForm===CRT}
                  openModal={setCurrentForm}
                  documentUrl={CRTUrl}/>
                  Civil Resolution Tribunal (CRT) - Action Notice Response
              </button>
            </div>
            <div className="center-button">
              <button onClick={() => setCurrentForm(latePayment)} className="btn btn-outline btn-primary-1 text-uppercase margin-top form-link-buttons">
                <StrataDocumentsModal
                  open={currentForm===latePayment}
                  openModal={setCurrentForm}
                  documentUrl={latePaymentUrl}/>
                Late Payment Dispute
              </button>
            </div>
          </section>
          </Col>
          <Col md={6} className="half-section">
          <section className="section background">
            <div className="center-button">
              <h1 className="text-uppercase font-weight-bold small-h1 middle" style={{marginBottom: '5px'}}>Document Orders</h1>
              <p className="text-md text-color-dark"></p>
            </div>
            <Row className="custom-sm-flex-order">
              <div className="center-button">
                <button onClick={() => setCurrentForm(documentOrder)} className="btn btn-outline btn-primary-1 text-uppercase form-link-buttons">
                  <StrataDocumentsModal
                    open={currentForm===documentOrder}
                    openModal={setCurrentForm}
                    documentUrl={documentOrderFormUrl}/>
                  Document Order Form (Form B&F)
                </button>
              </div>
            </Row>
          </section>
          </Col>
          <Col md={6} className="half-section">
          <section className="section background">
            <div className="center-button">
              <h1 className="text-uppercase font-weight-bold small-h1 middle" style={{marginBottom: '5px'}}>Payments & Finances</h1>
              <p className="text-md text-color-dark"></p>
            </div>
            <Row className="custom-sm-flex-order">
              <div className="center-button">
                <button onClick={() => setCurrentForm(financialHardship)} className="btn btn-outline btn-primary-1 text-uppercase form-link-buttons">
                  <StrataDocumentsModal
                    open={currentForm===financialHardship}
                    openModal={setCurrentForm}
                    documentUrl={financialHardshipUrl}/>
                  Financial Hardship Application
                </button>
              </div>
            </Row>
            <Row className="custom-sm-flex-order">
              <div className="center-button">
                <button onClick={() => setCurrentForm(PADAgreement)} className="btn btn-outline btn-primary-1 text-uppercase margin-top form-link-buttons">
                  <StrataDocumentsModal
                    open={currentForm===PADAgreement}
                    openModal={setCurrentForm}
                    documentUrl={PADAgreementUrl}/>
                  Pre-Authorized Debit Agreement
                </button>
              </div>
            </Row>
            <Row className="custom-sm-flex-order">
              <div className="center-button">
                <button onClick={() => setCurrentForm(PADWithdrawal)} className="btn btn-outline btn-primary-1 text-uppercase margin-top form-link-buttons">
                  <StrataDocumentsModal
                    open={currentForm===PADWithdrawal}
                    openModal={setCurrentForm}
                    documentUrl={PADWithdrawalUrl}/>
                  Pre-Authorized Debit Extra Withdrawal
                </button>
              </div>
            </Row>
          </section>
          </Col>
          <Col md={6} className="half-section">
          <section className="section background">
            <div className="center-button">
              <h1 className="text-uppercase font-weight-bold small-h1 middle" style={{marginBottom: '5px'}}>Renovation Requests</h1>
              <p className="text-md text-color-dark"></p>
            </div>
            <Row className="custom-sm-flex-order">
              <div className="center-button">
                <button onClick={() => setCurrentForm(renovationRequest)} className="btn btn-outline btn-primary-1 text-uppercase form-link-buttons">
                  <StrataDocumentsModal
                    open={currentForm===renovationRequest}
                    openModal={setCurrentForm}
                    documentUrl={renovationRequestUrl}/>
                  Renovation Request Form
                </button>
              </div>
            </Row>
          </section>
          </Col>
          </Row>
        </Col>
      </div>
    </Layout>
  )
}
export default StrataManagementDocuments
